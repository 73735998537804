import React, { useState } from 'react';
import { GridLayout } from '../components/GridLayout'
import { shuffle, multiplyArray } from '../utils';
import { Button } from 'react-bootstrap';

import arrowTop from 'open-iconic/svg/arrow-thick-top.svg';
import arrowRight from 'open-iconic/svg/arrow-thick-right.svg';
import arrowBottom from 'open-iconic/svg/arrow-thick-bottom.svg';
import arrowLeft from 'open-iconic/svg/arrow-thick-left.svg';

function Arrows() {
  const rows = 7, cols = 7

  const getContent = () => {
    const arrows = [
      <img width="100%" src={arrowTop} alt="top"/>,
      <img width="100%" src={arrowRight} alt="right"/>,
      <img width="100%" src={arrowBottom} alt="bottom"/>,
      <img width="100%" src={arrowLeft} alt="left"/>
    ]
    const content = multiplyArray([...arrows], rows * cols);
    shuffle(content);
    return content;
  }

  const [content, setContent] = useState(getContent());    

  const handleReload = () => {
    setContent(getContent);
  }

  return (
    <section
    className='vision arrows'>
    <header
        className='App-header'>
        Vision Training: arrows
        <br />
        <small>
            say the direction of the arrows | 
            say the opposite direction of the arrows
        </small>
    </header>
    <div className="vision-layout">
        <div className="buttonbar">
        <Button
            variant='success'
            onClick={handleReload}>Refresh</Button>            
        </div>

        <GridLayout className="vision-pqdb"
            row={rows}
            col={cols}
            content={content}
        />
    </div>
</section>
  )
}

export { Arrows };
