function GridLayout(props) {

    const { row, col, content, className } = props;
    const data = content;

    const createColumns = (ri) =>{
        var columns = [];
        for (let ci = 0; ci < col; ++ci )
        {
            const dataIndex = ci + (ri * col);

            let itemFragment = null;
            if (dataIndex < data.length) {
                itemFragment = <span>{data[dataIndex]}</span>
            }

            columns.push(
                <td
                    className="item"
                    key={ci}
                >
                        {itemFragment}
                </td>
            );
        }

        return columns;
    };

    const createRows = () => {
        const rows = [];
        for (let ri =0; ri < row; ++ri) {
            rows.push(
                <tr
                    key={ri}>
                    {createColumns(ri)}
                </tr>
            )
        }

        return rows;
    }

    return (
        <table className={className}>
            <tbody>
            {createRows()}
            </tbody>
        </table>    
    );
}

export { GridLayout };
