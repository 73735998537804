import React from 'react';
import {
  Switch,
  Route
 } from 'react-router-dom';
import { AppNavbar } from './components/AppNavbar'
import { Arrows } from './pages/Arrows';
import { LeftRight } from './pages/LeftRight'
import { Pqdb } from './pages/Pqdb'

function Layout() {


  return (
      <React.Fragment>
        <AppNavbar />
        <div className="App-layout">
          <Switch>
            <Route
              path='/arrows'>
                <Arrows />
              </Route>
            <Route
              path='/pqdb'>
                <Pqdb />
            </Route>
            <Route
              path='/'>
              <LeftRight />
            </Route>
          </Switch>
        </div>
      </React.Fragment>
  );
}

export { Layout };