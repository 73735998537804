function shuffle(array) {
  var currentIndex = array.length, temporaryValue, randomIndex;
  
  // While there remain elements to shuffle...
  while (0 !== currentIndex) {

    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
 }

/**
 * Duplicates items to meet the minimum number of items.
 * Returns the content that contains items that exceed minimum.
 * 
 * @param {[]} items 
 * @param {number} min 
 */
function multiplyArray(items, min) {
  const content = [];

  while (content.length < min) {
      content.push(...items);
  }

  return content;
}

export { shuffle, multiplyArray }
