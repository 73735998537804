import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { useHistory, useRouteMatch } from 'react-router-dom';

function AppNavbar() {

    const history = useHistory();
    const match = useRouteMatch();

    const handleNavChange = (eventKey, event) => {
        history.push(eventKey)
    };

    console.log('history:', history);
    console.log('match:', match);
    return (
        <Navbar
            bg='dark'
            variant='dark'
            onSelect={handleNavChange}
            sticky='top'
            >
        <Navbar.Brand
            href='/'>
            Vision Training
        </Navbar.Brand>
        <Navbar.Collapse>
            <Nav
                className='mr-auto'
                activeKey={history.location.pathname}
            >
            <Nav.Link eventKey={`${match.url}`}>Left &amp; Right</Nav.Link>
            <Nav.Link eventKey={`${match.url}pqdb`}>pqdb</Nav.Link>
            <Nav.Link eventKey={`${match.url}arrows`}>Arrows</Nav.Link>
            </Nav>
        </Navbar.Collapse>
        </Navbar>
    );
}

export { AppNavbar };