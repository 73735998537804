import React, { useState } from 'react';
import { GridLayout } from '../components/GridLayout'
import { shuffle, multiplyArray } from '../utils';
import { Button } from 'react-bootstrap';

function LeftRight(props) {
    const rows = 5;
    const cols = 5;

    const getContent = () => {
        const alphabet = 'abcdefghijklmnopqrstuvwxyz'
        const content = multiplyArray([...alphabet], rows * cols);        
        shuffle(content);
        return content;
      };

    const handleReload = () => {
        setContent(getContent());
    }

    const [content, setContent] = useState(getContent());

    return (
        <section
            className='vision'>
            <header
                className='App-header'
                >
                Vision Training: Left & Right
                <br/>
                <small>
                    Read letters from left and right.
                </small>
            </header>
            <div className="vision-layout">
                <div className="buttonbar">
                <Button
                    variant='success'
                    onClick={handleReload}>Refresh</Button>            
                </div>
                <GridLayout
                    row={rows}
                    col={cols}
                    content={content}
                    className="vision-leftright"
                />
            </div>
        </section>
    );
}

export { LeftRight };