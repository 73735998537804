import React, { useState } from 'react';
import { GridLayout } from '../components/GridLayout'
import { shuffle, multiplyArray } from '../utils';
import { Button } from 'react-bootstrap'

function Pqdb(props) {

    const rows = 6, cols = 6

    const getContent = () => {
        const pqdb = 'pqdb'
        const content = multiplyArray([...pqdb], rows * cols);
        shuffle(content);
        return content;
    };

    const [content, setContent] = useState(getContent());    

    const handleReload = () => {
      setContent(getContent());
    }

    return (
        <section
            className='vision'>
            <header
                className='App-header'>
                Vision Training: pqdb
                <br />
                <small>
                    say the letter and act:
                    p (left leg) | q (right leg) | b (left arm) | d (right arm)
                </small>
            </header>
            <div className="vision-layout">
                <div className="buttonbar">
                <Button
                    variant='success'
                    onClick={handleReload}>Refresh</Button>            
                </div>

                <GridLayout className="vision-pqdb"
                    row={rows}
                    col={cols}
                    content={content}
                />
            </div>
        </section>
    );
}

export { Pqdb };
